import React from 'react';
import {Container, Row, Col, Accordion} from "react-bootstrap";
import Layout from "../layout/layout";
import Breadcrumbs from "../components/breadcrumbs";

function Index(props) {
    return (
        <Layout>
            <Breadcrumbs link1="TekRevol" link2="Privacy Policy"/>
            <div className="privacy-policy p-100">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                            <h1>Privacy Policy for TekRevol</h1>
                            <p> Thank you for choosing to be part of our community at Tekrevol LLC
                                (“<span>Company</span>”,
                                “<span>we</span>”, “<span>us</span>”, or “<span>our</span>”). We are committed to
                                protecting
                                your
                                personal information and your right to
                                privacy. If
                                you have any questions or concerns about our policy, or our practices with regards to
                                your
                                personal information, please contact us at info@tekrevol.com.
                            </p>
                            <p>When you visit our website and use our services, you trust us with your personal
                                information. We
                                take your privacy very seriously. In this privacy policy, we seek to explain to you in
                                the
                                clearest way possible what information we collect, how we use it and what rights you
                                have in
                                relation to it. We hope you take some time to read through it carefully, as it is
                                important. If
                                there are any terms in this privacy policy that you do not agree with, please
                                discontinue use of
                                our services.</p>
                            <p>This privacy policy applies to all information collected through our and/or any related
                                services,
                                sales, marketing or events (we refer to them collectively in this privacy policy as the
                                "<span>Services</span>").</p>
                            <p><span>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</span>
                            </p>
                            <p className="mt-5 text-right">Last updated February 03, 2020</p>
                            <div className="new_faq">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            WHAT INFORMATION DO WE COLLECT?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <h4>Personal information you disclose to us</h4>
                                            <p><span>In Short:</span> We collect personal information that you provide
                                                to us
                                                such as name,
                                                address, contact information, passwords and security data, payment
                                                information,
                                                and social media login data.</p>
                                            <p> We collect personal information that you voluntarily provide to us when
                                                expressing an interest in obtaining information about us or our products
                                                and
                                                services, when participating in activities on the or otherwise
                                                contacting
                                                us.</p>
                                            <p> The personal information that we collect depends on the context of your
                                                interactions with us and the choices you make and the products and
                                                features you
                                                use. The personal information we collect can include the following:
                                            </p>
                                            <p><span>Credentials.</span> We collect passwords, password hints, and
                                                similar
                                                security
                                                information used for authentication and account access.</p>
                                            <p><span>Social Media Login Data.</span> We provide you with the option to
                                                register
                                                using social
                                                media account details, like your Facebook, Twitter or other social media
                                                account. If you choose to register in this way, we will collect the
                                                Information
                                                described in the section called
                                                <span>"HOW DO WE HANDLE YOUR SOCIAL LOGINS"</span> below.</p>
                                            <p>All personal information that you provide to us must be true, complete
                                                and
                                                accurate, and you must notify us of any changes to such personal
                                                information.</p>
                                            <h4>Information automatically collected</h4>
                                            <p><span>In Short:</span> Some information — such as IP address and/or
                                                browser and
                                                device
                                                characteristics — is collected automatically when you visit our website
                                            </p>
                                            <p>We automatically collect certain information when you visit, use or
                                                navigate the
                                                website . This information does not reveal your specific identity (like
                                                your
                                                name or contact information) but may include device and usage
                                                information, such
                                                as your IP address, browser and device characteristics, operating
                                                system,
                                                language preferences, referring URLs, device name, country, location,
                                                information about how and when you use our and other technical
                                                information. This
                                                information is primarily needed to maintain the security and operation
                                                of our
                                                users , and for our internal analytics and reporting purposes</p>
                                            <p>Like many businesses, we also collect information through cookies and
                                                similar
                                                technologies.</p>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> We only share information with your consent, to
                                                comply
                                                with laws, to provide you with services, to protect your rights, or to
                                                fulfill
                                                business obligations.</p>
                                            <p> We may process or share data based on the following legal basis:</p>
                                            <p> We use the information we collect or receive: </p>
                                            <ul>
                                                <li>
                                                    <span> Consent:</span> We may process your data if you have given us
                                                    specific consent to
                                                    use your personal information in a specific purpose.
                                                </li>
                                                <li><span>Legitimate Interests:</span> We may process your data when it
                                                    is
                                                    reasonably necessary to achieve our legitimate business interests.
                                                </li>
                                                <li><span>Performance of a Contract:</span> Where we have entered into a
                                                    contract with you, we may process your personal information to
                                                    fulfill the
                                                    terms of our contract.
                                                </li>
                                                <li><span>Legal Obligations:</span> We may disclose your information
                                                    where we
                                                    are legally required to do so in order to comply with applicable
                                                    law,
                                                    governmental requests, a judicial proceeding, court order, or legal
                                                    process,
                                                    such as in response to a court order or a subpoena (including in
                                                    response to
                                                    public authorities to meet national security or law enforcement
                                                    requirements).
                                                </li>
                                                <li><span>Vital Interests:</span> We may disclose your information where
                                                    we
                                                    believe it is necessary to investigate, prevent, or take action
                                                    regarding
                                                    potential violations of our policies, suspected fraud, situations
                                                    involving
                                                    potential threats to the safety of any person and illegal
                                                    activities, or as
                                                    evidence in litigation in which we are involved.
                                                </li>
                                            </ul>
                                            <p>More specifically, we may need to process your data or share your
                                                personal
                                                information in the following situations:</p>
                                            <ul>
                                                <li>
                                                    <span>Vendors, Consultants and Other Third-Party Service Providers.</span>
                                                    We may share your data with third party vendors, service providers,
                                                    contractors or agents who perform services for us or on our behalf
                                                    and
                                                    require access to such information to do that work. Examples
                                                    include:
                                                    payment processing, data analysis, email delivery, hosting services,
                                                    customer service and marketing efforts. We may allow selected third
                                                    parties
                                                    to use tracking technology, which will enable them to collect data
                                                    about how
                                                    you interact with the over time. This information may be used to,
                                                    among
                                                    other things, analyze and track data, determine the popularity of
                                                    certain
                                                    content and better understand online activity. Unless described in
                                                    this
                                                    Policy, we do not share, sell, rent or trade any of your information
                                                    with
                                                    third parties for their promotional purposes.
                                                </li>
                                                <li><span>Business Transfers.</span> We may share or transfer your
                                                    information
                                                    in
                                                    connection with, or during negotiations of, any merger, sale of
                                                    company
                                                    assets, financing, or acquisition of all or a portion of our
                                                    business to
                                                    another company.
                                                </li>
                                                <li><span>Third-Party Advertisers.</span> We may use third-party
                                                    advertising
                                                    companies to
                                                    serve ads when you visit our website. These companies may use
                                                    information
                                                    about your visits to our Website(s) and other websites that are
                                                    contained in
                                                    web cookies and other tracking technologies in order to provide
                                                    advertisements about goods and services of interest to you.
                                                </li>
                                            </ul>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> We only share information with your consent, to
                                                comply
                                                with laws, to provide you with services, to protect your rights, or to
                                                fulfill
                                                business obligations.</p>
                                            <p> We may process or share data based on the following legal basis:</p>
                                            <p> We use the information we collect or receive: </p>
                                            <ul>
                                                <li>
                                                    <span> Consent:</span> We may process your data if you have given us
                                                    specific consent to
                                                    use your personal information in a specific purpose.
                                                </li>
                                                <li><span>Legitimate Interests:</span> We may process your data when it
                                                    is
                                                    reasonably necessary to achieve our legitimate business interests.
                                                </li>
                                                <li><span>Performance of a Contract:</span> Where we have entered into a
                                                    contract with you, we may process your personal information to
                                                    fulfill the
                                                    terms of our contract.
                                                </li>
                                                <li><span>Legal Obligations:</span> We may disclose your information
                                                    where we
                                                    are legally required to do so in order to comply with applicable
                                                    law,
                                                    governmental requests, a judicial proceeding, court order, or legal
                                                    process,
                                                    such as in response to a court order or a subpoena (including in
                                                    response to
                                                    public authorities to meet national security or law enforcement
                                                    requirements).
                                                </li>
                                                <li><span>Vital Interests:</span> We may disclose your information where
                                                    we
                                                    believe it is necessary to investigate, prevent, or take action
                                                    regarding
                                                    potential violations of our policies, suspected fraud, situations
                                                    involving
                                                    potential threats to the safety of any person and illegal
                                                    activities, or as
                                                    evidence in litigation in which we are involved.
                                                </li>
                                            </ul>
                                            <p>More specifically, we may need to process your data or share your
                                                personal
                                                information in the following situations:</p>
                                            <ul>
                                                <li>
                                                    <span>Vendors, Consultants and Other Third-Party Service Providers.</span>
                                                    We may share your data with third party vendors, service providers,
                                                    contractors or agents who perform services for us or on our behalf
                                                    and
                                                    require access to such information to do that work. Examples
                                                    include:
                                                    payment processing, data analysis, email delivery, hosting services,
                                                    customer service and marketing efforts. We may allow selected third
                                                    parties
                                                    to use tracking technology, which will enable them to collect data
                                                    about how
                                                    you interact with the over time. This information may be used to,
                                                    among
                                                    other things, analyze and track data, determine the popularity of
                                                    certain
                                                    content and better understand online activity. Unless described in
                                                    this
                                                    Policy, we do not share, sell, rent or trade any of your information
                                                    with
                                                    third parties for their promotional purposes.
                                                </li>
                                                <li><span>Business Transfers.</span> We may share or transfer your
                                                    information
                                                    in
                                                    connection with, or during negotiations of, any merger, sale of
                                                    company
                                                    assets, financing, or acquisition of all or a portion of our
                                                    business to
                                                    another company.
                                                </li>
                                                <li><span>Third-Party Advertisers.</span> We may use third-party
                                                    advertising
                                                    companies to
                                                    serve ads when you visit our website. These companies may use
                                                    information
                                                    about your visits to our Website(s) and other websites that are
                                                    contained in
                                                    web cookies and other tracking technologies in order to provide
                                                    advertisements about goods and services of interest to you.
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> We may use cookies and other tracking technologies
                                                to
                                                collect and store your information.</p>
                                            <p> We may use cookies and similar tracking technologies (like web beacons
                                                and
                                                pixels) to access or store information. Specific information about how
                                                we use
                                                such technologies and how you can refuse certain cookies is set out in
                                                our
                                                Cookie Policy.</p>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> If you choose to register or log in to our
                                                services using
                                                a social media account, we may have access to certain information about
                                                you.</p>
                                            <p> Our offer you the ability to register and login using your third party
                                                social
                                                media account details (like your Facebook or Twitter logins). Where you
                                                choose
                                                to do this, we will receive certain profile information about you from
                                                your
                                                social media provider. The profile Information we receive may vary
                                                depending on
                                                the social media provider concerned, but will often include your name,
                                                e-mail
                                                address, friends list, profile picture as well as other information you
                                                choose
                                                to make public.</p>
                                            <p> We will use the information we receive only for the purposes that are
                                                described
                                                in this privacy policy or that are otherwise made clear to you on the .
                                                Please
                                                note that we do not control, and are not responsible for, other uses of
                                                your
                                                personal information by your third party social media provider. We
                                                recommend
                                                that you review their privacy policy to understand how they collect, use
                                                and
                                                share your personal information, and how you can set your privacy
                                                preferences on
                                                their sites and apps.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            HOW LONG DO WE KEEP YOUR INFORMATION?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> We keep your information for as long as necessary
                                                to
                                                fulfill the purposes outlined in this privacy policy unless otherwise
                                                required
                                                by law.</p>
                                            <p> We will only keep your personal information for as long as it is
                                                necessary for
                                                the purposes set out in this privacy policy, unless a longer retention
                                                period is
                                                required or permitted by law (such as tax, accounting or other legal
                                                requirements). No purpose in this policy will require us keeping your
                                                personal
                                                information for longer than 90 days.</p>
                                            <p>When we have no ongoing legitimate business need to process your personal
                                                information, we will either delete or anonymize it, or, if this is not
                                                possible
                                                (for example, because your personal information has been stored in
                                                backup
                                                archives), then we will securely store your personal information and
                                                isolate it
                                                from any further processing until deletion is possible.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                            HOW DO WE KEEP YOUR INFORMATION SAFE?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> We aim to protect your personal information
                                                through a
                                                system of organizational and technical security measures.</p>
                                            <p> We have implemented appropriate technical and organizational security
                                                measures
                                                designed to protect the security of any personal information we process.
                                                However, please also remember that we cannot guarantee that the internet
                                                itself
                                                is 100% secure. Although we will do our best to protect your personal
                                                information, transmission of personal information to and from our
                                                website is at
                                                your own risk. You should only access the services within a secure
                                                environment.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>
                                            DO WE COLLECT INFORMATION FROM MINORS?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> We do not knowingly collect data from or market to
                                                children under 18 years of age.</p>
                                            <p> We do not knowingly solicit data from or market to children under 18
                                                years of
                                                age. By using the website, you represent that you are at least 18 or
                                                that you
                                                are the parent or guardian of such a minor and consent to such minor
                                                dependent’s
                                                use of the website . If we learn that personal information from users
                                                less than
                                                18 years of age has been collected, we will deactivate the account and
                                                take
                                                reasonable measures to promptly delete such data from our records. If
                                                you become
                                                aware of any data we have collected from children under age 18, please
                                                contact
                                                us at info@tekrevol.com.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>
                                            WHAT ARE YOUR PRIVACY RIGHTS?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span> In some regions, such as the European Economic
                                                Area, you
                                                have rights that allow you greater access to and control over your
                                                personal
                                                information. You may review, change, or terminate your account at any
                                                time.</p>
                                            <p> In some regions (like the European Economic Area), you have certain
                                                rights under
                                                applicable data protection laws. These may include the right (i) to
                                                request
                                                access and obtain a copy of your personal information, (ii) to request
                                                rectification or erasure; (iii) to restrict the processing of your
                                                personal
                                                information; and (iv) if applicable, to data portability. In certain
                                                circumstances, you may also have the right to object to the processing
                                                of your
                                                personal information. To make such a request, please use the <a
                                                    href="https://app.termly.io/dashboard/website/342109/privacy-policy#contact">
                                                    contact details</a>
                                                provided below. We will consider and act upon any request in accordance
                                                with
                                                applicable data protection laws.</p>
                                            <p>If we are relying on your consent to process your personal information,
                                                you have
                                                the right to withdraw your consent at any time. Please note however that
                                                this
                                                will not affect the lawfulness of the processing before its
                                                withdrawal.</p>
                                            <p>If you are resident in the European Economic Area and you believe we are
                                                unlawfully processing your personal information, you also have the right
                                                to
                                                complain to your local data protection supervisory authority. You can
                                                find their
                                                contact details here:<a
                                                    href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                                                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
                                            </p>
                                            <p><span>Cookies and similar technologies:</span> Most Web browsers are set
                                                to
                                                accept cookies by
                                                default. If you prefer, you can usually choose to set your browser to
                                                remove
                                                cookies and to reject cookies. If you choose to remove cookies or reject
                                                cookies, this could affect certain features or services of our website .
                                                To
                                                opt-out of interest-based advertising by advertisers on our visit
                                                <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/.</a>
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="9">
                                        <Accordion.Header>
                                            CONTROLS FOR DO-NOT-TRACK FEATURES
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>Most web browsers and some mobile operating systems and mobile
                                                applications
                                                include a Do-Not-Track (“DNT”) feature or setting you can activate to
                                                signal
                                                your privacy preference not to have data about your online browsing
                                                activities monitored and collected. No uniform technology standard for
                                                recognizing and implementing DNT signals has been finalized. As such, we
                                                do
                                                not currently respond to DNT browser signals or any other mechanism that
                                                automatically communicates your choice not to be tracked online. If a
                                                standard for online tracking is adopted that we must follow in the
                                                future,
                                                we will inform you about that practice in a revised version of this
                                                privacy
                                                policy.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="10">
                                        <Accordion.Header>
                                            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span>Yes, if you are a resident of California, you are
                                                granted
                                                specific rights regarding access to your personal information.</p>
                                            <p> California Civil Code Section 1798.83, also known as the “Shine The
                                                Light” law,
                                                permits our users who are California residents to request and obtain
                                                from us,
                                                once a year and free of charge, information about categories of personal
                                                information (if any) we disclosed to third parties for direct marketing
                                                purposes
                                                and the names and addresses of all third parties with which we shared
                                                personal
                                                information in the immediately preceding calendar year. If you are a
                                                California
                                                resident and would like to make such a request, please submit your
                                                request in
                                                writing to us using the contact information provided below.</p>
                                            <p>If you are under 18 years of age, reside in California, and have a
                                                registered
                                                account, you have the right to request removal of unwanted data that you
                                                publicly post on the . To request removal of such data, please contact
                                                us using
                                                the contact information provided below, and include the email address
                                                associated
                                                with your account and a statement that you reside in California. We will
                                                make
                                                sure the data is not publicly displayed, but please be aware that the
                                                data may
                                                not be completely or comprehensively removed from our systems.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="11">
                                        <Accordion.Header>
                                            DO WE MAKE UPDATES TO THIS POLICY?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p><span>In Short:</span>Yes, we will update this policy as necessary to
                                                stay
                                                compliant with relevant laws.</p>
                                            <p> We may update this privacy policy from time to time. The updated version
                                                will be
                                                indicated by an updated “Revised” date and the updated version will be
                                                effective
                                                as soon as it is accessible. If we make material changes to this privacy
                                                policy,
                                                we may notify you either by prominently posting a notice of such changes
                                                or by
                                                directly sending you a notification. We encourage you to review this
                                                privacy
                                                policy frequently to be informed of how we are protecting your
                                                information.
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="12">
                                        <Accordion.Header>
                                            HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p>If you have questions or comments about this policy, you may email us at
                                                info@tekrevol.com or by post to:</p>
                                            <p> Tekrevol LLC<br/>
                                                39899 Balentine Drive, Newark, CA 94560, United States<br/>
                                                Newark, CA 94560<br/>
                                                United States<br/>
                                            </p>
                                            <h4>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>
                                            <p>
                                                Based on the laws of some countries, you may have the right to request
                                                access
                                                to the personal information we collect from you, change that
                                                information,
                                                or delete it in some circumstances. To request to review, update, or
                                                delete your personal information, please submit a request form by
                                                clicking <a
                                                href="https://app.termly.io/notify/c09a6cea-fb46-470c-a5aa-742bfee76890">
                                                here</a>. We will respond to your request within 30 days
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
}

export default Index;